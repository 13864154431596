import config from "@/config/Config.js";

export default class RequestBuilder {

    constructor(configurationID, accessToken) {
        this.configurationID = configurationID;
        this.accessToken = accessToken;
    }

    async get(url, body) {
        return this.reponseBuilder("GET", url, body);
    }

    async post(url, body) {
        return this.reponseBuilder("POST", url, body);
    }

    async put(url, body) {
        return this.reponseBuilder("PUT", url, body);
    }

    async patch(url, body) {
        return this.reponseBuilder("PATCH", url, body);
    }

    async delete(url) {
        return this.reponseBuilder("DELETE", url);
    }

    async reponseBuilder(method, url, body) {
        const request_url = config.api_endpoint + url;

        let headers = {
            "Graveyard": this.configurationID,
            "Authorization": "Bearer " + this.accessToken,
        }

        if (!(body instanceof FormData)) {
            body = JSON.stringify(body);
            headers["Content-Type"] = "application/json";
        }

        try {
            return await fetch(request_url, {
                method: method,
                body: body,
                headers: headers
            });
        } catch (error) {
            // TODO: Better error handling
            console.log(error);
            return null;
        }
    }
}