import store from "@/store";
import RestClient from "./rest/RestClient";
import ConfigurationParser from "./ConfigurationParser";

export default class UserConfiguration {

    constructor() {
        this.configuration = store.getters["user/getUserConfiguration"];
        this.user = store.getters["user/getDatabaseUser"];

        if (!this.configuration) {
            this.configuration = {};
        }

        if (typeof this.configuration === "string") {
            this.configuration = JSON.parse(this.configuration);
        }

        if (!("settings" in this.configuration)) {
            this.configuration.settings = {};
        }

    }

    getAccentColor() {
        // Get from user configuration
        let accentColor = this.configuration.settings?.accentColor;

        // Get from default configuration if not defined in user configuration 
        if (!accentColor) {
            const configurationParser = new ConfigurationParser();
            const properties = configurationParser.getGraveManagementProperties();
            accentColor = properties.basecolor;
        }

        return accentColor;
    }

    setAccentColor(accentColor) {
        this.configuration.settings.accentColor = accentColor;
        this.updateConfiguration();
    }

    getFontScale() {
        // Get from user configuration
        let fontScale = this.configuration.settings?.fontScale;

        // Get default value if not defined in user configuration
        return fontScale ? fontScale : 1;
    }

    setFontScale(fontScale) {
        this.configuration.settings.fontScale = fontScale;
        this.updateConfiguration();
    }

    getDashboardTables() {
        const graveyard_id = store.getters["user/getCurrentConfiguration"]?.graveyard_id || null;
        return this.configuration.graveyards?.[graveyard_id]?.dashboard?.tables;
    }

    async postDashboardTable(table) {
        const graveyard_id = store.getters["user/getCurrentConfiguration"]?.graveyard_id || null;
        if (this.configuration.graveyards?.[graveyard_id]?.dashboard?.tables)
            this.configuration.graveyards[graveyard_id].dashboard.tables.push(table);
        else if (this.configuration.graveyards?.[graveyard_id]?.dashboard)
            this.configuration.graveyards[graveyard_id].dashboard.tables = [table];
        else
            this.configuration.graveyards = {
                [graveyard_id]: {
                    "dashboard": {
                        "tables": [table]
                    }
                }
            }

        this.updateConfiguration();
    }

    async putDashboardTable(id, table) {
        id, table

        this.updateConfiguration();
    }

    async deleteDashboardTable(id) {
        id
        this.updateConfiguration();
    }

    async updateConfiguration() {
        store.dispatch("user/setUserConfiguration", this.configuration);

        const restClient = new RestClient();
        restClient.patchCurrentUser({
            config: JSON.stringify(this.configuration)
        });
    }
}