<template>
    <DesktopDrawer :drawerItems="this.getRelevantDrawerItems" cds-layout="display@sm:block display:none" />

    <PhoneDrawer :drawerItems="this.getRelevantDrawerItems" cds-layout="display@sm:none"
        v-if="$store.state.phoneDrawerExpanded" />
</template>

<script>
import store from '@/store';
import DesktopDrawer from './desktop/DesktopDrawer.vue';
import PhoneDrawer from './phone/PhoneDrawer.vue';
import { drawerItemsMixin } from './DrawerItemsMixin';

export default {
    mixins: [drawerItemsMixin],
    setup() {
        // Needs to be initialized here, because PhoneDrawer can be changed from 'HeaderComponent' 
        store.state.phoneDrawerExpanded = false;
    },
    components: {
        DesktopDrawer,
        PhoneDrawer
    }
}
</script>

<style scoped></style>