const settingsMixin = {
    methods: {
        setAccentColor(hexColor) {
            // Set Clarity colors
            document.body.style.setProperty("--cds-alias-status-info", hexColor);
            document.body.style.setProperty("--cds-alias-status-info-tint", this.lighten(hexColor, 90));

            // Set Quasar colors
            document.body.style.setProperty("--q-primary", hexColor);
        },
        setFontScale(scale) {
            // Set Clarity font scale
            document.documentElement.style.setProperty("--cds-global-scale-typography", scale);
        },
        lighten(color, percent) {
            // Ensure the color starts with a hash
            if (color[0] === '#') {
                color = color.slice(1);
            }

            // Parse the r, g, b values from the hex string
            const num = parseInt(color, 16);
            let r = (num >> 16);
            let g = (num >> 8) & 0x00FF;
            let b = num & 0x0000FF;

            // Calculate the target color as white (255, 255, 255)
            const white = 255;

            // Increase each RGB component proportionally towards white
            r = Math.round(r + ((white - r) * (percent / 100)));
            g = Math.round(g + ((white - g) * (percent / 100)));
            b = Math.round(b + ((white - b) * (percent / 100)));

            // Ensure the values are within RGB bounds (0 to 255)
            r = Math.min(255, Math.max(0, r));
            g = Math.min(255, Math.max(0, g));
            b = Math.min(255, Math.max(0, b));

            // Convert back to a hexadecimal string
            const newColor = (r << 16) | (g << 8) | b;
            return `#${newColor.toString(16).padStart(6, '0')}`;
        }
    }
}

export { settingsMixin };