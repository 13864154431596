import store from "@/store";

export default class ConfigurationParser {

    constructor() {
        this.configuration = store.getters["management/getConfiguration"];
    }

    getGraveManagementProperties() {
        return this.configuration.properties;
    }

    getGraveManagementName() {
        return this.configuration.name;
    }

    getVisibleTables() {
        let visibleTables = [];

        this.configuration.tables.forEach((table) => {
            if (table.view) {

                let drawerItem = {
                    "id": table.name,
                    "label": table.label,
                    "icon": table.icon
                }

                visibleTables.push(drawerItem);
            }
        });
        return visibleTables;
    }

    //Dynamic View Fields
    getFields(name) {
        let fields = []

        let table = this.configuration.tables.find(table => table.name == name);
        fields = table == undefined ? [] : table.fields;

        return fields; // -> [{"name": "NAME OF FIELD","type": "TYPE OF FIELD", ...},]
    }

    //Dynamic View Columns for Table
    getTableColumns(name) {
        let column_fields = [];

        const all_fields = this.getFields(name);
        column_fields = all_fields.filter(field => field.visible);

        return column_fields;
    }

    //Dynamic Form Fields in Blocks
    getFormBlocks(name) {
        const table = this.configuration.tables.find(table => table.name == name);
        const form = table == undefined ? [] : table.form;

        let form_blocks = []
        form.forEach(object => {
            const block_id = object.block;
            const block_label = object.label;
            const block_fields = this.getFields(name).filter(field => field.form_block == block_id);

            const block = {
                "label": block_label,
                "fields": block_fields
            }

            form_blocks.push(block);
        });

        return form_blocks; // -> [{"label": "NAME OF SECTION", "fields": [{field1, field2,...}]},]
    }

    getTableLabelFromName(name) {
        let tableName = "";

        let table = this.configuration.tables.find(table => table.name == name);
        tableName = table == undefined ? "" : table.label;

        return tableName;
    }

    getRelations(name) {
        let relations = [];

        let table = this.configuration.tables.find(table => table.name == name);
        relations = table == undefined ? [] : table.relations;

        return relations;
    }

    getReferences(name) {
        let references = [];

        let fields = this.getFields(name);
        references = fields.filter((field) => field.reference);

        return references;
    }

    getVisibleReferences(name) {
        let visibleReferences = [];

        let allReferences = this.getReferences(name);
        let visibleTables = this.getVisibleTables();
        visibleReferences = allReferences.filter((reference) =>
            visibleTables.find((table) => table.id === reference.reference.table)
        );

        return visibleReferences;
    }

    getDetails(name) {
        let table = this.configuration.tables.find(table => table.name == name);
        return table?.details || {};
    }

    getDashboardTables() {
        return this.configuration.dashboard?.tables || [];
    }

    getRights() {
        return this.configuration.rights;
    }
}