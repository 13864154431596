<template>
    <div cds-layout="vertical align:stretch">
        <div cds-layout="vertical align:shrink" class="login-container">
            <div class="cross horizontal">
                <div class="cross vertical" />
                <div class="loading-bar" />
            </div>
            <div cds-layout="vertical gap:xxl" class="cross body">
                <span cds-text="section medium" class="loading-text">
                    Die Grabverwaltung wird vorbereitet ...
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';

export default {
    emits: ["onLoadingDone"],
    mounted: async function () {
        await this.startLoading();
        this.$emit("onLoadingDone");
    },
    methods: {
        startLoading: async function () {
            const cross_horizontal = document.querySelector(".cross.horizontal");
            cross_horizontal.style.borderBottom = "0.5px solid lightgray";
            cross_horizontal.style.boxShadow = "none";
            const loadingBar = document.querySelector(".loading-bar");
            const totalSteps = 200;
            let currentStep = 0;

            return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (currentStep < totalSteps) currentStep++;

                    const progressPercentage = (currentStep / totalSteps) * 90;
                    loadingBar.style.width = progressPercentage + "%";


                    if (currentStep >= totalSteps && store.getters["management/isConfigurationLoading"] == false) {
                        loadingBar.style.width = "100 %";
                        clearInterval(interval);
                        resolve();
                    }
                }, 20);
            });
        }
    }
}
</script>

<style scoped>
.login-container {
    align-items: center;
    margin-top: calc(150px + 48.41px);
}

.loading-text {
    color: #8A8A8A;
}

.cross.horizontal {
    position: relative;
    border-bottom: 1.5px solid #526B51;
    box-shadow: 0px 4px 2px -2px #00000040;
    min-width: 500px;
    width: 60%;
    max-width: 800px;
    padding-left: 150px;
    padding-bottom: 5px;
}

.cross.vertical {
    position: absolute;
    border-right: 1.5px solid #526B51;
    height: 500px;
    left: 120px;
    top: calc(-40px - 48.41px);
}

.cross.body {
    min-width: 500px;
    width: 60%;
    padding-left: 150px;
    max-width: 700px;
    margin-top: 100px;
}

.loading-bar {
    position: absolute;
    left: 0px;
    bottom: -1px;
    border-bottom: 1.5px solid #526B51;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 0%;

    transition: width 20ms linear;
}
</style>