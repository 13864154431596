import ConfigurationParser from '@/js/ConfigurationParser.js';
import { ClarityIcons, gridChartIcon, organizationIcon, fileIcon, homeIcon, infoStandardIcon, userIcon, employeeGroupIcon, colorPaletteIcon, shieldIcon } from "@cds/core/icon";

const drawerItemsMixin = {
    mounted() {
        ClarityIcons.addIcons(gridChartIcon, organizationIcon, fileIcon, homeIcon, infoStandardIcon, userIcon, employeeGroupIcon, colorPaletteIcon, shieldIcon);
        this.loadSvgIcons();
    },
    data() {
        const defaultItems = this.getDefaultDrawerItems();
        const settingItems = this.getSettingsDrawerItems();

        return {
            defaultItems,
            settingItems,
        }
    },
    watch: {
        getRelevantDrawerItems: function () {
            this.loadSvgIcons();
        },
    },
    computed: {
        getRelevantDrawerItems: function () {
            const settings_route = this.$route.path.includes('settings');
            return settings_route ? this.settingItems : this.defaultItems;
        }
    },
    methods: {
        getDefaultDrawerItems: function () {
            const visibleTables = new ConfigurationParser().getVisibleTables();

            let items = [
                {
                    route: "/dashboard",
                    label: "Dashboard",
                    shape: {
                        name: "grid-chart",
                        svg: null // no svg! imported icon at top
                    },
                    isActive: () => this.$route.name == 'Dashboard' || this.$route.name == 'Home'
                },
                {
                    route: "/workflow",
                    label: "Grabsatz anlegen",
                    shape: {
                        name: "organization",
                        svg: null // no svg! imported icon at top
                    },
                    isActive: () => this.$route.name == 'Workflow'
                }
            ];

            // Add dynamic Items
            visibleTables.forEach(table => {
                const item = {
                    route: {
                        path: '/dynamic/list',
                        query: { name: table.id }
                    },
                    label: table.label,
                    shape: {
                        name: table.label,
                        svg: table.icon
                    },
                    isActive: () => this.$route.query.name === table.id
                };
                items.push(item)
            });

            // Add documents under dynamic items
            items.push({
                route: "/documents",
                label: "Dokumente",
                shape: {
                    name: "file",
                    svg: null // no svg! imported icon at top
                },
                isActive: () => this.$route.name == 'Documents'
            });

            return items;
        },
        getSettingsDrawerItems: function () {

            let items = [
                {
                    route: "/settings/profile",
                    label: "Profil & Sicherheit",
                    shape: {
                        name: "user",
                        svg: null // no svg! imported icon at top
                    },
                    isActive: () => this.$route.name === 'Profile'
                },
                {
                    route: "/settings/app",
                    label: "App Einstellungen",
                    shape: {
                        name: "color-palette", // CHANGE ICON
                        svg: null // no svg! imported icon at top
                    },
                    isActive: () => this.$route.name === 'AppSettings'
                },
                // {
                //     route: "/settings/rights",
                //     label: "Datenschutz",
                //     shape: {
                //         name: "shield", // CHANGE ICON
                //         svg: null // no svg! imported icon at top
                //     },
                //     isActive: () => this.$route.name === 'Rights'
                // },
                // {
                //     route: "/settings/admin",
                //     label: "Admin Bereich",
                //     shape: {
                //         name: "employee-group", // CHANGE ICON
                //         svg: null // no svg! imported icon at top
                //     },
                //     isActive: () => this.$route.name === 'Admin'
                // },
                {
                    route: "/settings/about",
                    label: "Über Grabis",
                    shape: {
                        name: "info-standard",
                        svg: null // no svg! imported icon at top
                    },
                    isActive: () => this.$route.name === 'About'
                },
                {
                    route: "/dashboard",
                    label: "Zurück zur Grabverwaltung",
                    shape: {
                        name: "home",
                        svg: null // no svg! imported icon at top
                    },
                    isActive: () => null
                }
            ];

            return items;
        },
        loadSvgIcons: function () {
            this.getRelevantDrawerItems.forEach((drawerItem) => {
                if (drawerItem.shape.svg) {
                    const icon_name = drawerItem.shape.name;
                    const icon_svg = drawerItem.shape.svg;

                    ClarityIcons.addIcons([icon_name, icon_svg]);
                }
            });
        },
    }
}

export { drawerItemsMixin };