<template>
  <div class="drawer">
    <div class="main" cds-layout="vertical p:md">
      <div cds-layout="horizontal gap:sm align:right align:vertical-center">
        <cds-button action="flat" status="neutral" @click="closePhoneDrawer">
          <span cds-text="caption">Übersicht schließen</span>
          <cds-icon shape="times"></cds-icon>
        </cds-button>
      </div>
      <div cds-layout="vertical gap:lg m-t:md m-b:md">
        <!-- Dynamic Drawer Items -->
        <NavigationTemplate v-for="drawerItem in this.drawerItems" :key="drawerItem" :route="drawerItem.route">
          <template v-slot:icon>
            <cds-icon :shape="drawerItem.shape.name" size="md" />
          </template>
          <template v-slot:name>
            {{ drawerItem.label }}
          </template>
        </NavigationTemplate>

        <!-- Einstellungen -->
        <NavigationTemplate :route="'/settings/profile'" v-if="!this.$route.path.includes('settings')">
          <template v-slot:icon>
            <cds-icon shape="cog" size="md" />
          </template>
          <template v-slot:name> Einstellungen </template>
        </NavigationTemplate>

        <!-- Logout -->
        <NavigationTemplate @click="logout">
          <template v-slot:icon>
            <cds-icon shape="logout" size="md" />
          </template>
          <template v-slot:name> Logout </template>
        </NavigationTemplate>
      </div>
    </div>

    <div class="bottom" />
  </div>
</template>

<script>
import "@cds/core/internal-components/close-button/register.js";
import store from "@/store";
import NavigationTemplate from "./NavigationTemplate.vue";
import logout from "@/js/authentication/Logout.js";
import { ClarityIcons, cogIcon } from "@cds/core/icon";

export default {
  props: {
    drawerItems: Array,
  },
  components: {
    NavigationTemplate,
  },
  mounted() {
    ClarityIcons.addIcons(cogIcon);
  },
  methods: {
    closePhoneDrawer: function () {
      store.state.phoneDrawerExpanded = false;
    },
    logout: async function () {
      await logout();
    },
  },
};
</script>

<style scoped>
.drawer {
  position: absolute;
  width: 100dvw;
  height: 100dvh;
  z-index: 101;
}

.main {
  background-color: white;
  position: relative;
}

.bottom {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
