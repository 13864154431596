<template>
    <div cds-layout="align:shrink">
        <cds-card class="pointer-cursor" @click="$emit('onClick')">
            <div cds-layout="vertical gap:xxl align:center p-b:xl">
                <img v-if="imageUrl" :src="this.imageUrl" :alt="`Bild ${this.name}`">
                <p :cds-layout="imageUrl ? '' : 'p-t:xl'" cds-text="h1" class="text-no-select">{{ this.name }}</p>
            </div>
        </cds-card>
    </div>
</template>

<script>
import '@cds/core/card/register.js';

export default {
    emits: ["onClick"],
    props: {
        name: {
            type: String,
            default: "Kein Name"
        },
        imageUrl: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>
img {
    width: 250px;
    height: 175px;
    object-fit: cover;
}

.text-no-select {
    user-select: none;
}

.pointer-cursor {
    cursor: pointer
}
</style>