<template>
    <div cds-layout="horizontal m:xs gap:xs align:vertical-center" @click="this.changeRoute()">
        <span>
            <slot name="icon" />
        </span>
        <span cds-text="section semibold">
            <slot name="name" />
        </span>
    </div>
</template>

<script>
import store from '@/store';

export default {
    props: {
        route: String
    },
    methods: {
        changeRoute: function () {
            this.$router.push(this.route);
            store.state.phoneDrawerExpanded = false;
        }
    }
}
</script>

<style></style>