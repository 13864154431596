<template>
    <div class="header">
        <div cds-layout="horizontal align:stretch align:vertical-center p-x:sm gap:xxs">
            <!-- Back button -->
            <cds-icon-button cds-layout="align:shrink align:left" @click="this.goBack()">
                <cds-icon shape="angle" direction="left" />
            </cds-icon-button>

            <!-- Copy button -->
            <cds-icon-button v-if="this.showAccesstokenButton" cds-layout="align:shrink" @click="copyAccesstoken">
                <cds-icon shape="copy" />
            </cds-icon-button>

            <!-- Hamburger menu button -->
            <cds-icon-button cds-layout="align:shrink" @click="this.toggleOptions()">
                <cds-icon shape="ellipsis-vertical" />
            </cds-icon-button>
        </div>
        <div v-if="this.showOptions" class="options-container" @click="this.showOptions = false">
            <div cds-layout="horizontal align:shrink">
                <div class="options" cds-layout="vertical align:stretch">
                    <div class="text" v-if="showOption('edit_dashboard')">Dashboard anpassen</div>
                    <cds-divider v-if="showOption('edit_dashboard')" />
                    <div class="text" @click="this.routeTo('/')" v-if="showOption('gravemanagemt')">Grabverwaltung</div>
                    <cds-divider v-if="showOption('gravemanagemt')" />
                    <div class="text" @click="this.routeTo('/settings/about')">App Info</div>
                    <cds-divider />
                    <div class="text" v-if="showOption('export')">Seite exportieren</div>
                    <cds-divider v-if="showOption('export')" />
                    <div class="text" @click="this.routeTo('/settings/profile')" v-if="showOption('settings')">
                        Einstellungen
                    </div>
                    <cds-divider v-if="showOption('settings')" />
                    <div class="text" @click="this.$emit('onChangeConfiguration')">Grabverwaltung wechseln</div>
                    <cds-divider />
                    <div class="text" @click="this.logout()">Abmelden</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ClarityIcons, ellipsisVerticalIcon, angleIcon, copyIcon } from '@cds/core/icon';
import '@cds/core/divider/register.js';
import logout from '@/js/authentication/Logout';
import ConfigurationParser from '@/js/ConfigurationParser';
import store from '@/store';

export default {
    emits: ["onChangeConfiguration"],
    mounted() {
        ClarityIcons.addIcons(ellipsisVerticalIcon, angleIcon, copyIcon);
        this.updateMenuOptions();
        this.checkAccesstokenRight();
    },
    data() {
        return {
            showOptions: false,
            menu_options: [],
            showAccesstokenButton: false
        }
    },
    watch: {
        $route() {
            this.updateMenuOptions();
        }
    },
    methods: {
        updateMenuOptions: function () {
            const menu_options = this.$router.currentRoute.value.meta["menu_options"];
            if (menu_options == null || menu_options == undefined) return;
            this.menu_options = JSON.parse(JSON.stringify(menu_options));
        },
        toggleOptions: function () {
            this.showOptions = !this.showOptions;
        },
        checkAccesstokenRight: function () {
            // Show the button to copy the accesstoken if the user has the corresponding right
            const configurationParser = new ConfigurationParser();
            const rights = configurationParser.getRights();

            if (rights.accesstoken) {
                this.showAccesstokenButton = true;
            }
        },
        copyAccesstoken: function () {
            navigator.clipboard.writeText(store.getters["user/getIdToken"]);
        },
        logout: async function () {
            await logout();
        },
        showOption: function (option) {
            return this.menu_options.includes(option);
        },
        goBack: function () {
            this.$router.back();
        },
        routeTo: function (path) {
            this.$router.push(path);
            this.toggleOptions();
        }
    }
}
</script>

<style scoped>
.header {
    background-color: var(--cds-alias-status-info);
    color: white;
    min-height: 52px;
    max-height: 52px;
}

.options {
    background-color: white;
    z-index: 99999;
    position: absolute;
    right: 0px;
    margin-top: 52px;
    min-width: 250px;
    border: 1px solid #B4B4B4;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.options-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 99998;
}

.options .text {
    font-size: calc(16px * var(--cds-global-scale-typography));
    padding: 10px;
    color: #000000;
}

.options div:hover {
    background-color: #B4B4B4;
    cursor: pointer;
}
</style>