import RequestBuilder from "./RequestBuilder.js";
// import Beispielkonfiguration from "../../../../BeispielKonfiguration.json"
import store from "@/store/index.js";

export default class RestClient {

    constructor(configuration = store.getters["user/getCurrentConfiguration"], idToken = store.getters["user/getIdToken"]) {
        this.requestBuilder = new RequestBuilder(configuration?.graveyard_id, idToken);
    }

    async getGraveyards() {
        const endpoint = "/graveyards";

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return null;
            });
    }

    async getConfiguration() {
        const endpoint = "/config";

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return null;
            });
    }

    async getVersion() {
        const endpoint = "/version";

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return null;
            });
    }

    async getTableRows(table) {
        const endpoint = `/table/${table}`;

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return [];
            });
    }

    async getFormData(table) {
        const endpoint = `/table/${table}/form`;

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async getAllRelations(table, id) {
        const endpoint = `/table/${table}/${id}/find`;
        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async postObject(table, requestBody) {
        const endpoint = `/table/${table}`;

        return await this.requestBuilder.post(endpoint, requestBody)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async getObject(table, id) {
        const endpoint = `/table/${table}/${id}`;

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async patchObject(table, id, requestBody) {
        const endpoint = `/table/${table}/${id}`;

        return await this.requestBuilder.patch(endpoint, requestBody)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async deleteObject(table, id, archive = true) {
        let endpoint = `/table/${table}/${id}`;

        if (!archive) {
            endpoint += "?action=delete";
        }

        return await this.requestBuilder.delete(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async getDocuments() {
        const endpoint = "/documents";

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return null;
            });
    }

    async postDocument(requestBody) {
        const endpoint = "/documents";

        return await this.requestBuilder.post(endpoint, requestBody)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async getDocument(id) {
        const endpoint = `/documents/${id}`;

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async deleteDocument(id) {
        const endpoint = `/documents/${id}`;

        return await this.requestBuilder.delete(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async patchDocument(id, requestBody) {
        const endpoint = `/documents/${id}`;

        return await this.requestBuilder.patch(endpoint, requestBody)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async postUser(requestBody) {
        const endpoint = "/user";

        return await this.requestBuilder.post(endpoint, requestBody)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async getUser() {
        const endpoint = "/user";

        return await this.requestBuilder.get(endpoint)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response.json();
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async patchUser(uid, requestBody) {
        const endpoint = `/user/${uid}`;

        return await this.requestBuilder.patch(endpoint, requestBody)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }

    async patchCurrentUser(requestBody) {
        const endpoint = "/user";

        return await this.requestBuilder.patch(endpoint, requestBody)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            });
    }

    async postUserRole(requestBody) {
        const endpoint = "/user";

        return await this.requestBuilder.post(endpoint, requestBody)
            .then(response => {
                if (!response.ok) throw new Error("Not 200 response", { cause: response });
                return response;
            })
            .catch(error => {
                console.log(error)
                return {};
            });
    }
}