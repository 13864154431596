const util = {
    namespaced: true,
    state: () => ({
        alerts: [],
        cache: {
            table: {}
        }
    }),
    getters: {
        getTableCache: (state) => (name) => {
            const cache = state.cache.table[name];
            if (cache == undefined) return [];
            return cache;
        },
        getDocumentsCache(state) {
            return state.cache.documents;
        },
        getAlerts(state) {
            return state.alerts;
        }
    },
    mutations: {
        updateTableCache(state, { name, data }) {
            if (data == undefined) data = [];
            state.cache.table[name] = data;
        },
        updateDocumentsCache(state, data) {
            if (data == undefined) data = [];
            state.cache.documents = data;
        },
        pushAlert(state, alert) {
            state.alerts.push(alert);
        }
    },
    actions: {
        updateTableCache({ commit }, { name, data }) {
            commit('updateTableCache', { name, data });
        },
        updateDocumentsCache({ commit }, data) {
            commit('updateDocumentsCache', data);
        },
        pushAlert({ commit }, alert) {
            commit('pushAlert', alert);
        }
    },
}

export default util;