<template>
    <div cds-layout="vertical align:stretch">
        <div cds-layout="grid align@sm:vertical-stretch align:vertical-shrink" class="grid">
            <div cds-layout="col@sm:1 col:12" class="drawer">
                <DrawerComponent />
            </div>

            <div cds-layout="vertical col@sm:11 col:12" class="view">
                <HeaderComponent @on-change-configuration="this.$emit('onInitializationDone')" />
                <div cds-layout="grid align:stretch" class="content">
                    <router-view />
                </div>
            </div>
        </div>
    </div>


    <div class="alert">
        <div cds-layout="vertical gap:md">
            <AlertComponent v-for="alert in $store.getters['util/getAlerts']" :key="alert" :status="alert.status"
                :message="alert.message" />
        </div>
    </div>

</template>

<script>
import HeaderComponent from './components/header/HeaderComponent.vue';
import DrawerComponent from './components/drawer/DrawerComponent.vue';
import AlertComponent from "@/components/alert/AlertComponent.vue"
import store from './store';

export default {
    emits: ["onInitializationDone", "onLogin", "onSelected", "onLoadingDone"],
    components: {
        HeaderComponent,
        DrawerComponent,
        AlertComponent
    },
    beforeMount() {
        // Set title for browser tab
        const configuration = store.getters["user/getCurrentConfiguration"];
        document.title = `Grabverwaltung - ${configuration.graveyard_name}`;
    }
}
</script>

<style scoped>
.loading-screen {
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid {
    flex: 1;
    max-height: 100vh;
}

@media only screen and (max-width: 767px) {

    /* For mobile phones: */
    .drawer {
        position: absolute;
    }
}

.drawer {
    height: 100vh;
}

.view {
    max-height: 100vh;
}

.content {
    overflow: auto;
}

.alert {
    position: absolute;
    z-index: 999;
    top: 70px;
    right: 20px;
}
</style>