<template>
  <div class="drawer">
    <cds-navigation expanded>
      <!-- <cds-navigation-start /> -->
      <DrawerHeader />
      <div v-for="drawerItem in this.drawerItems" :key="drawerItem">
        <cds-navigation-item :active="drawerItem.isActive()">
          <router-link :to="drawerItem.route" cds-layout="p-t:sm p-b:sm">
            <cds-icon :shape="drawerItem.shape.name" size="20" />
            {{ drawerItem.label }}
          </router-link>
        </cds-navigation-item>
      </div>
    </cds-navigation>
  </div>
</template>

<script>
import "@cds/core/navigation/register.js";
import "@cds/core/divider/register.js";
import DrawerHeader from "./DrawerHeader.vue";

export default {
  props: {
    drawerItems: Array
  },
  components: {
    DrawerHeader,
  },
};
</script>

<style scoped>
.drawer {
  height: 100%;
  border-right: 1px solid lightgray;
}

cds-navigation-item {
  --cds-alias-object-interaction-background-selected: var(--cds-alias-status-info-tint);
  --cds-alias-object-interaction-background-hover: var(--cds-alias-status-info-tint);
  --cds-alias-object-interaction-background-active: var(--cds-alias-status-info-tint);
  --cds-alias-object-interaction-background-highlight: var(--cds-alias-status-info);
}
</style>
