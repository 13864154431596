import { createRouter, createWebHashHistory } from "vue-router";
import getUserFromBrowser from "@/js/authentication/GetUserFromBrowser.js";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/DashboardView.vue"),
    meta: {
      auth: true,
      // menu_options: ["edit_dashboard", "settings", "export"],
      menu_options: ["settings"],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../LoginView.vue"),
    meta: { auth: false },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/DashboardView.vue"),
    meta: {
      auth: true,
      // menu_options: ["edit_dashboard", "settings", "export"],
      menu_options: ["settings"],
    },
  },
  {
    path: "/workflow",
    name: "Workflow",
    component: () => import("../views/WorkflowView.vue"),
    meta: {
      auth: true,
      menu_options: ["settings"],
    },
  },
  {
    path: "/documents",
    name: "Documents",
    component: () => import("../views/DocumentsView.vue"),
    meta: {
      auth: true,
      menu_options: ["settings"],
    },
  },
  {
    path: "/dynamic/list",
    name: "DynamicList",
    props: true,
    component: () => import("@/views/TableView.vue"),
    meta: {
      auth: true,
      // menu_options: ["settings", "export"],
      menu_options: ["settings"],
    },
  },
  {
    path: "/dynamic/create",
    name: "DynamicCreate",
    props: true,
    component: () => import("@/views/FormView.vue"),
    meta: {
      auth: true,
      menu_options: ["settings"],
    },
  },
  {
    path: "/dynamic/detail",
    name: "DynamicDetail",
    props: true,
    component: () => import("@/views/DetailView.vue"),
    meta: {
      auth: true,
      menu_options: ["settings"],
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/SettingsView.vue"),
    meta: {
      auth: true,
      menu_options: ["gravemanagement"],
    },
    children: [
      {
        name: 'Profile',
        path: 'profile',
        component: () => import('@/components/settings/ProfileComponent.vue'),
        meta: {
          title: "Profil und Sicherheit"
        }
      },
      {
        name: 'AppSettings',
        path: 'app',
        component: () => import('@/components/settings/AppSettingsComponent.vue'),
        meta: {
          title: "App Einstellungen"
        }
      },
      {
        name: 'Rights',
        path: 'rights',
        component: () => import('@/components/settings/RightsComponent.vue'),
        meta: {
          title: "Datenschutz und Rechte"
        }
      },
      {
        name: 'Admin',
        path: 'admin',
        component: () => import('@/components/settings/AdminComponent.vue'),
        meta: {
          title: "Admin Bereich"
        }
      },
      {
        name: 'About',
        path: 'about',
        component: () => import('@/components/settings/AboutComponent.vue'),
        meta: {
          title: "Über Grabis"
        }
      }
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("./NotFound.vue"),
    meta: {
      auth: true,
      menu_options: ["gravemanagement", "settings"],
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth && (await getUserFromBrowser()) == null)
    return next("/login");
  if (to.name == "Login" && (await getUserFromBrowser()) != null)
    return next("/dashboard");
  return next();
});

export default router;
